import React from 'react'

import {
  COLORS,
  TitleLarge2,
  TitleMedium2,
  TitleXLarge2,
} from 'ethos-design-system'

import Markdown, {
  MARKDOWN_NODE_RESTRICTION_SETS,
} from '../../../global/Markdown'

export const H1Responsive = ({
  color,
  copy,
  className,
}: {
  color?: COLORS
  copy: string
  className?: string
}): JSX.Element => (
  <div className={className}>
    <div className="sm:block md:hidden" suppressHydrationWarning>
      <TitleMedium2.Serif.Book500 color={color} element="h1">
        <Markdown
          input={copy}
          allowedMarkdownTypes={MARKDOWN_NODE_RESTRICTION_SETS.LINK_ONLY}
        ></Markdown>
      </TitleMedium2.Serif.Book500>
    </div>
    <div className="hidden md:block lg:hidden" suppressHydrationWarning>
      <TitleLarge2.Serif.Book500 color={color} element="h1">
        <Markdown
          input={copy}
          allowedMarkdownTypes={MARKDOWN_NODE_RESTRICTION_SETS.LINK_ONLY}
        ></Markdown>
      </TitleLarge2.Serif.Book500>
    </div>
    <div className="hidden lg:block" suppressHydrationWarning>
      <TitleXLarge2.Serif.Book500 color={color} element="h1">
        <Markdown
          input={copy}
          allowedMarkdownTypes={MARKDOWN_NODE_RESTRICTION_SETS.LINK_ONLY}
        ></Markdown>
      </TitleXLarge2.Serif.Book500>
    </div>
  </div>
)
